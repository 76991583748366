import { KeySetterActionType, RemoveKeyAction, SetKeyAction } from './CustomNotification.type';

/**
 * Action to set the `key` value in the state.
 * @param {string} key - The key to be set in the state.
 * @returns {SetKeyAction} - The action to dispatch.
 * @namespace TrouperPwa/Store/CustomNotification/Action/setKey
 */
export const setKey = (key: string, productId?: string): SetKeyAction => ({
    type: KeySetterActionType.SET_KEY,
    key,
    productId,
});

/**
 * Action to remove the `key` from the state (reset it).
 * @returns {RemoveKeyAction} - The action to dispatch.
 * @namespace TrouperPwa/Store/CustomNotification/Action/removeKey
 */
export const removeKey = (): RemoveKeyAction => ({
    type: KeySetterActionType.REMOVE_KEY,
});
