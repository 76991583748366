/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import { fetchQuery } from '@scandipwa/scandipwa/src/util/Request/Query';

import CmsBlockQuery from 'Query/CmsBlock.query';
import SourceCmsBlockContainer from 'SourceComponent/CmsBlock/CmsBlock.container';
import { ReactElement } from 'Type/Common.type';

import CmsBlock from './CmsBlock.component';

/** @namespace TrouperPwa/Component/CmsBlock/Container */
export class CmsBlockContainer extends SourceCmsBlockContainer {
    fetchStaticBlocks = async () => {
        const { identifier } = this.props;
        try {
            const query = CmsBlockQuery.getQuery({ identifiers: [identifier] });
            const response = await fetchQuery(query);

            if (!response?.cmsBlocks?.items.length) {
                return;
            }

            const footerElements = document.querySelectorAll('[aria-label="Footer"]');

            if (footerElements.length > 1){
                footerElements[1].remove(); // Removes the second element (index 1)
            }

            if (document.getElementsByClassName('footer-link-row').length > 0){
                const isIntialised = document.getElementById('intialise-sliders');

                if (isIntialised){
                    isIntialised.click();
                }
            }

            if (identifier === 'footer_copyrights_block' && response){
                const isBacktoTop = document.getElementById('back-to-top');

                if (isBacktoTop){
                    isBacktoTop.click();
                }
            }
            this.setState({ cmsBlock: response?.cmsBlocks?.items[0] });
        } catch (err) {
            console.log(err);
        }
    };

    _getCmsBlock(): void {
        const { identifier } = this.props;

        if (this.state.cmsBlock && this.state.cmsBlock.identifier === identifier) {
            return; // Skip if already fetched
        }
        this.fetchStaticBlocks();
    }

    render(): ReactElement {
        return (
            <CmsBlock
              { ...this.containerProps() }
            />
        );
    }
}

export default CmsBlockContainer;
