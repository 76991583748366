/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import { RENDER_NEWSLETTER } from '@scandipwa/scandipwa/src/component/Footer/Footer.config';
import NewsletterSubscription from '../NewsletterSubscription';

import CmsBlock from '../CmsBlock/CmsBlock.container';
import RenderWhenVisible from 'Component/RenderWhenVisible';
import { FooterComponent as SourceFooterComponent } from 'SourceComponent/Footer/Footer.component';
import { FooterComponentProps } from 'SourceComponent/Footer/Footer.type';
import { ReactElement } from 'Type/Common.type';
import { noopFn } from 'Util/Common';

import './Footer.style';
import BackToTopComponent from 'Component/BackToTop/BackToTop.component';

/**
 * Page footer
 * @class Footer
 * @namespace TrouperPwa/Component/Footer/Component */
export class FooterComponent extends SourceFooterComponent {
    static defaultProps: Partial<FooterComponentProps> = {
        copyright: '',
        isVisibleOnMobile: false,
        onItemClick: noopFn,
    };

    interval: NodeJS.Timeout | null = null;

    renderMap = {
        [RENDER_NEWSLETTER]: {
            render: this.renderNewsletterSubscriptionBlock.bind(this),
        },
    };

    renderNewsletterSubscriptionBlock(): ReactElement {
        return <NewsletterSubscription key="NewsletterSubscription" />;
    }

    intialisePopularSearchesAccordion = () => {
        const headings = document.querySelectorAll('.popular-searches-heading h4');
    
        headings.forEach(function(heading) {
            // Ensure 'plus-icon' is added if not already
            if (!heading.classList.contains('plus-icon')) {
                heading.classList.add('plus-icon');
            }
    
            // Only set up the event listener once
            if (!heading.hasAttribute('data-listener')) {
                heading.addEventListener('click', handleSearchAccordionClick);
                heading.setAttribute('data-listener', 'true'); // Mark the element as having an event listener
            }
        });
    
        // Handle accordion toggle click events
        function handleSearchAccordionClick(event:any) {
            const heading = event.currentTarget;
            const container = heading.closest('.popular-searches');
            const accordionContent = container.querySelector('.acc-container'); // Ensure this exists
    
            // Toggle based on the current state of the heading icon
            if (heading.classList.contains('plus-icon')) {
                // If the heading has the 'plus-icon', show the content and change to 'minus-icon'
                accordionContent.classList.remove('hidden');
                accordionContent.style.display = 'block'; // For smooth slideDown, use CSS transitions
                heading.classList.remove('plus-icon');
                heading.classList.add('minus-icon');
                heading.style.color = '#234FCC';
            } else if (heading.classList.contains('minus-icon')) {
                // If the heading has the 'minus-icon', hide the content and change to 'plus-icon'
                accordionContent.classList.add('hidden');
                accordionContent.style.display = 'none'; // For smooth slideUp, use CSS transitions
                heading.classList.remove('minus-icon');
                heading.classList.add('plus-icon');
                heading.style.color = '';
            }
        }
    };

     footerAccordion = () => {
        const slTime = 500;
        // Add the 'up-arrow' class to all Footer-ColumnTitle elements
        const columnTitles = document.querySelectorAll('.Footer-ColumnTitle');
        columnTitles.forEach((title:any) => {
            title.classList.add('up-arrow');
            const mobileAccordion = title.closest('.Footer-Column').querySelector('.Footer-ColumnContent');
            mobileAccordion.style.display = 'none'; // Initially hide all content (up-arrow state)
        });
    
        // Iterate over each Footer-ColumnTitle to handle the initial state
        const columns = document.querySelectorAll('.Footer-Column > .Footer-ColumnTitle') as any;
        columns.forEach((title:any) => {
            const mobileAccordion = title.closest('.Footer-Column').querySelector('.Footer-ColumnContent') as any;
    
            if (title.classList.contains('active')) {
                mobileAccordion.style.display = 'block';
                title.classList.remove('up-arrow');
                title.classList.add('down-arrow');
            } else {
                mobileAccordion.style.display = 'none';
                title.classList.remove('down-arrow');
                title.classList.add('up-arrow');
            }
    
            // Add the click event listener only if it hasn't been added already
            if (!title.hasAttribute('data-listener')) {
                title.addEventListener('click', function handleClick() {
                    const mobileAccordion = title.closest('.Footer-Column').querySelector('.Footer-ColumnContent') as any;
                    // Remove 'active' and reset arrows for other titles
                    columns.forEach((otherTitle:any) => {
                        if (otherTitle !== title) {
                            otherTitle.classList.remove('active');
                            otherTitle.classList.remove('down-arrow');
                            otherTitle.classList.add('up-arrow');
                            const otherAccordion = otherTitle.closest('.Footer-Column').querySelector('.Footer-ColumnContent') as any;
                            otherAccordion.style.display = 'none'; // Hide other accordion content
                        }
                    });
    
                    // Toggle the 'active' class on the clicked title
                    title.classList.toggle('active');
    
                    if (title.classList.contains('active')) {
                        // Slide down the accordion
                        mobileAccordion.style.transition = `height ${slTime}ms ease-in-out`;
                        mobileAccordion.style.height = `${mobileAccordion.scrollHeight}px`; // animate height expansion
                        title.classList.remove('up-arrow');
                        title.classList.add('down-arrow');
                        mobileAccordion.style.display = 'block'; // Show the accordion
                        mobileAccordion.style.height = 'auto'; // animate height collapse
                        mobileAccordion.style.overflow = 'hidden'; // animate height collapse
                    } else {
                        // Slide up the accordion
                        mobileAccordion.style.transition = `height ${slTime}ms ease-in-out`;
                        mobileAccordion.style.height = '0px'; // animate height collapse
                        title.classList.remove('down-arrow');
                        title.classList.add('up-arrow');
                        mobileAccordion.style.display = 'none'; // Hide the accordion
                    }
                });
    
                // Mark this element as having an event listener
                title.setAttribute('data-listener', 'true');
            }
        });
    };
    
    renderBackBlock(): void {
        const backToTopElement = document.getElementById('back-to-top');
        if (backToTopElement) {
            // Clear any existing content and add the button dynamically
            backToTopElement.innerHTML = `
                <button class="BackToTop-Button">
                    <span>Back To Top</span>
                </button>
            `;
    
            // Now select the button and add the event listener
            const button = backToTopElement.querySelector('button');
            if (button) {
                button.addEventListener('click', this.scrollToTop.bind(this));  // Bind `this` to make sure the method refers to the class context
            }
        }
    }
    
    // Make sure you have this method somewhere in the scope of your script:
     scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    render(): ReactElement {
        const { isVisibleOnMobile, device } = this.props;

        if (isVisibleOnMobile && !device.isMobile) {
            return null;
        }



return (
    <RenderWhenVisible>
        <footer block="Footer" aria-label="Footer">
            <CmsBlock identifier="footer_delivery_content" />
            <CmsBlock identifier="footer_popular_search_block" />

            <div block="parent-footer-links">
                <div block="footer-links-main">
                <CmsBlock identifier="footer_links_new_block" />
                </div>
                <div block="parent-social-links">
                    <CmsBlock identifier="footer_social_block" />
                    <CmsBlock identifier="footer_newsletter_block" />
                    { this.renderMap[RENDER_NEWSLETTER].render() }
                </div>
            </div>
            <CmsBlock identifier="footer_copyrights_block" />
        </footer>
        <div id="back-to-top"  aria-hidden="true"  onClick={() => this.renderBackBlock()}></div>
        <div id="intialise-sliders" aria-hidden="true" onClick={() => {this.footerAccordion();this.intialisePopularSearchesAccordion();}}></div>
    </RenderWhenVisible>
);
    }
}

export default FooterComponent;
