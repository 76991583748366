/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { setKey } from 'Store/CustomNotification/CustomNotification.action';
import NewsletterSubscriptionDispatcher from 'Store/NewsletterSubscription/NewsletterSubscription.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { NotificationType } from 'Store/Notification/Notification.type';
import { ReactElement } from 'Type/Common.type';
import { FieldData } from 'Util/Form/Form.type';
import history from 'Util/History';
import { RootState } from 'Util/Store/Store.type';

import NewsletterSubscription from './NewsletterSubscription.component';
import {
    NewsletterSubscriptionContainerFunctions,
    NewsletterSubscriptionContainerProps,
    NewsletterSubscriptionContainerState,
    NewsletterSubscriptionMapDispatchProps,
    NewsletterSubscriptionMapStateProps,
} from './NewsletterSubscription.type';

/** @namespace TrouperPwa/Component/NewsletterSubscription/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): NewsletterSubscriptionMapStateProps => ({
    allowGuestSubscribe: state.ConfigReducer.newsletter_subscription_allow_guest_subscribe,
    isSignedIn: state.MyAccountReducer.isSignedIn,
    notifications: state.NotificationReducer.notifications,
    ...state,
});

/** @namespace TrouperPwa/Component/NewsletterSubscription/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch): NewsletterSubscriptionMapDispatchProps => ({
    subscribeToNewsletter: (email) => NewsletterSubscriptionDispatcher.subscribeToNewsletter(dispatch, email),
    showErrorNotification: (message) => dispatch(showNotification(NotificationType.ERROR, message)),
    setGlobalState: (state) => dispatch(setKey(state)),
});

/** @namespace TrouperPwa/Component/NewsletterSubscription/Container */
export class NewsletterSubscriptionContainer extends PureComponent<
NewsletterSubscriptionContainerProps,
NewsletterSubscriptionContainerState
> {
    containerFunctions: NewsletterSubscriptionContainerFunctions = {
        onFormSubmit: this.onFormSubmit.bind(this),
    };

    state: NewsletterSubscriptionContainerState = {
        isLoading: false,
    };

    __construct(props: NewsletterSubscriptionContainerProps): void {
        super.__construct?.(props);

        this.onFormSubmitDone = this.onFormSubmitDone.bind(this);
    }

    containerProps(): { isLoading: boolean } {
        const { isLoading } = this.state;

        return { isLoading };
    }

    onFormSubmit(form: HTMLFormElement, fields: FieldData[]): void {
        const { setGlobalState } = this.props;
        setGlobalState({ newsletterSubscription: 'globalNotificationDisable' });
        const {
            subscribeToNewsletter,
            allowGuestSubscribe,
            isSignedIn,
            showErrorNotification,
        } = this.props;

        const {
            value: email,
        } = fields.find(({ name }) => name === 'newsletterEmail') || {};

        if (!email) {
            return;
        }

        if (!allowGuestSubscribe && !isSignedIn) {
            showErrorNotification(
                __('Guests can not subscribe to the newsletter. You must create an account or login to subscribe.'),
            );

            return;
        }

        this.setState({ isLoading: true });

        subscribeToNewsletter(email as string)
            .then(this.onFormSubmitDone)
            .catch(this.onFormSubmitDone);
    }

    onFormSubmitDone(): void {
        const { notifications } = this.props;
        const notificationDetail = notifications && Object.values(notifications);

        if (notificationDetail.length > 0) {
            const emailInputs = document.querySelectorAll('[name="newsletterEmail"]');

            if (emailInputs.length > 0 && notificationDetail[0].msgType === NotificationType.SUCCESS) {
                /* eslint-disable no-param-reassign */
                emailInputs.forEach((inputElement) => {
                    if (inputElement instanceof HTMLInputElement) {
                        inputElement.value = ''; // Clear the input field (no assignment to parameter)
                    }
                });
                /* eslint-enable no-param-reassign */
            }
        }
        this.setState({ isLoading: false });
    }

    render(): ReactElement {
        const { location: { pathname = '' } = {} } = history || {};
/* eslint-disable */
        return (
            <NewsletterSubscription
              { ...this.containerProps() }
              { ...this.containerFunctions }
              key={ pathname }
              // @ts-ignore
              notifications={ this.props.notifications }
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NewsletterSubscriptionContainer);
