/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import React from 'react';

import { FieldType } from 'Component/Field/Field.config';
import { NewsletterSubscriptionComponent as SourceNewsletterSubscriptionComponent } from
    'SourceComponent/NewsletterSubscription/NewsletterSubscription.component';
import { ReactElement } from 'Type/Common.type';

import './NewsletterSubscription.style';

/**
 * Newsletter Subscription form
 * @class NewsletterSubscription
 * @namespace TrouperPwa/Component/NewsletterSubscription/Component
 */
export class NewsletterSubscriptionComponent extends SourceNewsletterSubscriptionComponent {
    renderActions(): ReactElement {
        return (
            <button
              type={ FieldType.SUBMIT }
              block="Button"
              mods={ { isHollow: true } }
              aria-label={ __('Submit') }
            >
                { __('Submit') }
            </button>
        );
    }
    /* eslint-disable */
    render(): ReactElement {
        return (
            <div block="NewsletterSubscription">
                { super.render() }
                { 
                // @ts-ignore
                this.props?.notifications
                // @ts-ignore
                && Object.values(this.props?.notifications).map((notification:any) => (
                    <div block="CustomNotificationList">
    <div block="CustomNotification Notification_type_error" mods={{[notification.msgType]:true}}><button block="CustomNotification-Button"> Close </button><p block="CustomNotification-Text">{notification.msgText}</p></div></div>
             )) }
            </div>
        );
    }
}

export default NewsletterSubscriptionComponent;
